import { utilityNavSX } from '@/components/content/Header/styles/utilityNav';
import { custServiceSx } from '@/components/content/Header/styles/custService';
import { ID } from '@/data/types/Basic';
import { useMediaQuery, useTheme, Button, Popover } from '@mui/material';
import { useHeader } from '@/data/Content/Header';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { getChildItem } from '@/utils/getChildItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocalization } from '@/data/Localization';
import { Container, Typography, Stack, Box } from '@mui/material';
import React, { FC, useCallback } from 'react';
import {
  HEADER_COPY,
  HEADER_TOP_NAV,
  HEADER_TOP_NAV_DROPDOWN,
} from '@/data/constants/marketing';
import { useNextRouter } from '@/data/Content/_NextRouter';

type Props = {
  id: ID;

};
export const UtilityNav: FC<Props> = ({ id }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const router = useNextRouter();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const HeaderLabels = useLocalization('Header');
  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;

  const show = useMediaQuery(theme.breakpoints.down('md'));
  const purposeStatement = useMediaQuery(theme.breakpoints.down(1100));
  const { contentItems } = useHeader(id);
  const { contentItem: utilityNav } = getChildItem(contentItems, HEADER_TOP_NAV);
  const { contentItem: headerCopy } = getChildItem(contentItems, HEADER_COPY);
  const { contentItem: customerServiceInfo } = getChildItem(contentItems, HEADER_TOP_NAV_DROPDOWN);

  return (
    <Box sx={utilityNavSX}>
      <Container maxWidth="xl">
        {show ? <Typography align='center'>
          {headerCopy ? <ContentRecommendation
            id={`${id}-${headerCopy.emsName}`}
            properties={headerCopy}
          /> : null
          }
        </Typography> :

          <Stack
            direction="row"
            justifyContent="space-between" // Adjust as needed
            alignItems="center"

          >
            <Typography
              /* position="absolute"
              right={!purposeStatement ? "42%" : "50%"}
              marginLeft="0 auto" */
              /* marginLeft="30rem" */
              marginLeft="37.5%"

              variant="body2Bold">
              {headerCopy ? (
                <ContentRecommendation
                  id={`${id}-${headerCopy?.emsName}`}
                  properties={headerCopy}
                />
              ) : null}
            </Typography>

            <Stack direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}>

              {utilityNav ? <ContentRecommendation
                id={`${id}-${utilityNav.emsName}`}
                properties={utilityNav}
              /> : null
              }
              <Button onClick={handleDropdown} >{HeaderLabels.Actions.CustomerService.t()}<ArrowDropDownIcon /> </Button>
              <Popover
                id={popId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{ marginTop: '18px' }}
              >
                <Stack sx={custServiceSx} spacing={2} >
                  {
                    customerServiceInfo ? <ContentRecommendation
                      id={`${id}-${customerServiceInfo.emsName}`}
                      properties={customerServiceInfo}
                    /> : null
                  }</Stack>
              </Popover>
            </Stack>
          </Stack>}
      </Container>
    </Box>
  );
};
