import { headerBreak } from '@/components/content/Header/styles/break';
import { SxProps, Theme } from '@mui/material';

export const utilityNavSX: SxProps<Theme> = (theme) => ({
	backgroundColor: 'primary.main',
	color: 'primary.contrastText',
	fontSize: 12,
	padding: '8px 0',
	[theme.breakpoints.up('md')]: {
		padding: '8px 58px',
	},
	ul: {
		padding: 0,
		margin: 0,
	},
	li: {
		listStyleType: 'none',
		':after': {
			content: "'|'",
			position: 'relative',
			left: '7px',
		},
	},
	a: {
		display: headerBreak({ mobile: 'none', desktop: 'inline' }),
		color: '#fff',
		'&:hover': {
			color: '#fff',
		},
	},
	button: {
		color: '#fff',
		fontSize: '12px',
		padding: 0,
		fontWeight: 400,
		'&:hover': {
			color: '#fff',
			textDecoration: 'underline',
		},
	},
});
