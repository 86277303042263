/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerNavBarMenuItemSX = ({ dropDownState }: { dropDownState: boolean }): SxProps => ({
	position: 'static',
	transition: 'none',
	borderBottom: '4px solid',
	borderColor: dropDownState ? 'border.orange' : 'transparent',
	cursor: 'default',
	'&.MuiButton-text': {
		p: 2,
		backgroundColor: 'transparent',
		color: 'text.main',
		fontSize: 16,
		borderRadius: 0,
		marginBottom: '-1px',
		'&:first-of-type': {
			ml: -2,
		},
		'&:is(a):not([href])': {
			cursor: 'default',
		},
	},
});
