/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { MenuItem, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { ProcessedContent } from '@/data/types/Marketing';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { ID } from '@/data/types/Basic';
import { headerNavBarSubItemSX } from '@/components/content/Header/styles/navBar/subItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { NAVIGATION_MARKETING_SPOT_SUFFIX } from '@/data/constants/marketing';
import { LinkWrap } from '@/components/blocks/Linkable';


type Props = {
  index: number;
  label: string;
  identifier: ID;
  url: string | undefined;
  setHandler: (data: ProcessedContent[] | undefined | null, title: string) => void;
  setDropDownFull: React.Dispatch<React.SetStateAction<boolean>>;
  setDropdownState: React.Dispatch<React.SetStateAction<boolean>>;
  dropDownFull: boolean;
};

export const HeaderNavBarMenuItem = React.forwardRef(
  ({ index, url, label, identifier, dropDownFull, setDropdownState, setHandler, setDropDownFull, ...props }: Props, ref: React.Ref<HTMLAnchorElement> | null) => {
    const id = identifier + NAVIGATION_MARKETING_SPOT_SUFFIX;
    const { data } = useContentRecommendation(id);
    useEffect(() => {
      if (data) {
        if (index === 0) {
          setHandler(data, label);
        }
        setDropDownFull(true);
      }
    }, [data, index, label, setHandler, setDropDownFull]);

    return (
      <MenuItem key={identifier} >
        <LinkWrap href={url} >
          <Button disableRipple={true} onKeyUp={(e) => e.key === 'Enter' ? setDropdownState(false) : null} onClick={() => setDropdownState(false)} onFocus={() => setHandler(data, label)} onMouseOver={() => setHandler(data, label)} onMouseOut={() => setHandler(null, label)} component="a" ref={ref} {...props} sx={headerNavBarSubItemSX({ isParent: false, dropDownFull })}>
            {label}
            {data ? <ChevronRightIcon /> : null}
          </Button>
        </LinkWrap>
      </MenuItem >

    );
  }
);
