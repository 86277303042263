import { SxProps } from '@mui/material';

export const pdpDriverContainerSX: SxProps = {
    '& .MuiDrawer-paper': {
        boxSizing: 'border-box',
        width: '375px',
        padding: '24px 24px 0',
        backgroundColor: 'background.modal',
    },
	'.modal-close-btn': {
        position: 'absolute',
        top: '26px',
        right: '26px',
        cursor: 'pointer',
        '& svg': {
            color: 'icons.fillcolor',
        },
    },
};

export const pdpDriverContainerButtonSX: SxProps = {
    position: 'absolute',
    top: '26px',
    right: '26px',
    cursor: 'pointer',
    '& svg': {
        color: 'icons.fillcolor',
    },
};
