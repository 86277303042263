/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Autocomplete, Button, IconButton, InputAdornment, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FC, useMemo } from 'react';
import { headerSearchSX } from '@/components/content/Header/styles/search';
import { useSearchNavigation } from '@/data/Content/SearchNavigation';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';

type Option = {
	label: string;
	href?: string;
	identifier: string;
};

export const HeaderSearch: FC<{ mobile?: boolean }> = ({ mobile }) => {
	const { searchValue, suggest, onInputChange, onSubmit } = useSearchNavigation();
	const SearchNLS = useLocalization('SearchBar');
	const { user } = useUser();
	const isLoggedIn = user?.isLoggedIn ?? false;
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
	const options = useMemo<Option[]>(
		() =>
			suggest
				.map(({ identifier, entry }) =>
					identifier && entry
						? entry?.map(({ label, href }) => ({ label, href, identifier })) || []
						: []
				)
				.flat(1),
		[suggest]
	);
	const optionsWithoutCustomKeyword = options?.filter((option) => option?.identifier === 'Category' ? !option?.label?.includes('_Custom'): option);
	return (
		<Autocomplete
			freeSolo
			fullWidth
			disableClearable
			onChange={(_, item) => onSubmit(typeof item === 'string' ? { label: item } : item)}
			onInputChange={onInputChange}
			groupBy={({ identifier }) => identifier}
			options={optionsWithoutCustomKeyword}
			sx={headerSearchSX({ isMobile: mobile, isTablet })}
			renderInput={(params) => (
				<Stack direction="row" width="100%" marginLeft={isTablet ? '1.0rem': isLoggedIn ? '3.0rem' : '0'}>
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							placeholder: SearchNLS.SearchField.t(),
							slotProps: {
								input: { role: undefined, 'aria-expanded': undefined },
							},
							size: 'small',
							type: 'search',
							onKeyDown: (e) => {
								if (e.code === 'Enter' && e.currentTarget.value) {
									onSubmit({ label: e.currentTarget.value });
								}
							},
						}}
					/>
					<Button
						aria-label={SearchNLS.SearchField.t()}
						onClick={() => onSubmit({ label: searchValue })}
						variant="blue-search"
						sx={{
							height: '40px',
							marginBottom: '1px',
							marginLeft: '-0.3rem',
						}}
					>
						<SearchIcon sx={{ color: 'white' }} />
					</Button>
				</Stack>
			)}
		/>
	);
};


